import React, { useState, useEffect } from "react";
import styles from "./TripFilters.module.scss";
import {
  Spacing,
  Grid,
  Text,
  Checkbox,
  Button,
  MessageBar,
  GridItem,
} from "@leaseplan/ui";
import { getLabel, timezoneOffSetAdd } from "../../utils/index";
import { useDispatch, useSelector } from "react-redux";
import LPTripsDrawer from "../../Common/LPDrawer/LPTripsDrawer";
import {
  clearData,
  driverLastLocationInfo,
  hitViewportApi,
  isPanningMap,
  putSelectedDriver,
} from "../../Actions/level.action";
import { Link, TablePagination } from "@material-ui/core";
import { LPLoader } from "../../Common/LPLoader/LPLoader";
import {
  fetchLogRecordTrips,
  insertInitialFromTime,
  setLogRecordTripsColors,
} from "../../Actions/driver.action";
import moment from "moment-timezone";
import DatePicker from "../../Common/DatePicker/DatePicker";
import { addToast } from "../../Actions/toasts";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import EmptyPage from "./FleetDetails/EmptyPage";

const todaysDate = moment()
  .tz(
    sessionStorage.getItem("Timezone")
      ? sessionStorage.getItem("Timezone")
      : "America/New_York"
  )
  .format("YYYY-MM-DD");

const TripFilters = ({
  data,
  prismicData,
  isTripSideDrawerOpen,
  setIsTripSideDrawerOpen,
  onLayerFilterChange,
  poiFilters,
  onLevelFilterChange1,
  levelFilter,
  ...props
}) => {
  const [selectedDeviceId, setSelectedDeviceId] = useState([]);
  const levelFilteredDrivers = useSelector((state) => state.levels);
  const [selectedTripDate, setSelectedTripDate] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState([false]);
  const apiData = useSelector((state) => state.driver.fetchLogRecordTrips);
  const timelineSelector = useSelector((state) => state.timeline); // For Pagination

  const PAGE_LIMIT = 20;
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(PAGE_LIMIT);
  const [viewOrHide, setViewOrHide] = useState(false);

  const [selectedDeviceColors, setSelectedDeviceColors] = useState({});
  // New state to store selected device colors
  const onDeviceIdSelected = (id, event) => {
    const selectedDeviceIdTemp = { ...selectedDeviceId };
    if (event.checked && Object.keys(selectedDeviceId.length < 10)) {
      selectedDeviceIdTemp[id] = event.checked;
      // Generate color for the newly selected device
      const color = generateColor();
      setSelectedDeviceColors((prevColors) => ({ ...prevColors, [id]: color }));
    } else {
      delete selectedDeviceIdTemp[id]; // Remove color when device is unselected
      setSelectedDeviceColors((prevColors) => {
        const updatedColors = { ...prevColors };
        delete updatedColors[id];
        return updatedColors;
      });
    }
    setSelectedDeviceId(selectedDeviceIdTemp);
  }; // Generate a color based on the order of selected devices
  const generateColor = () => {
    const usedColors = Object.values(selectedDeviceColors);
    // Define an array of colors
    const colors = [
      "red",
      "blue",
      "green",
      "orange",
      "purple",
      "cyan",
      "magenta",
      "yellow",
      "teal",
      "pink",
    ];

    for (let color of colors) {
      if (!usedColors.includes(color)) {
        return color;
      }
    }
    return null;
  };

  useEffect(() => {
    if (apiData) {
      setIsLoading(apiData.isLoading);
    }
  }, [apiData]);

  const dispatch = useDispatch();

  const TripDeviceRow = ({
    id,
    rowData,
    onSelected,
    selectedDeviceId,
    color,
  }) => {
    return (
      <div
        className={styles.wrapper}
        style={{
          borderLeft: selectedDeviceId[id] ? `4px solid ${color} ` : "none",
        }}
      >
        <div className={styles.details}>
          <div className={styles.left}></div>
          <div className={styles.right}>
            <Spacing pv={1} />
            <Grid justifyContent="left">
              <Spacing ph={1} pv={1} />

              <Checkbox
                name="default"
                value={id}
                checked={selectedDeviceId[id]}
                onChange={(event) => onSelected(id, event)}
              />
              <Spacing ph={0.5} pv={1.5} />
              <div
                className={styles.fontstyle}
              >{`Device Name: ${rowData.deviceName}`}</div>
            </Grid>
          </div>
        </div>
      </div>
    );
  };

  useEffect(() => {
    //set the url for hardrefreshing functionality #444
    sessionStorage.setItem("urlEndPoint", window.location.pathname);
    updateTrips();
  }, [dispatch]);

  const generateRequestPayload = (deviceId) => {
    const DriverDateFromUTC = timezoneOffSetAdd(
      selectedTripDate ? selectedTripDate : todaysDate,
      "00:00:00",
      sessionStorage.getItem("TimezoneOffset")
    );
    const DriverDateToUTC = timezoneOffSetAdd(
      selectedTripDate ? selectedTripDate : todaysDate,
      "23:59:59",
      sessionStorage.getItem("TimezoneOffset")
    );
    return {
      isHistoric: timelineSelector.date === todaysDate ? false : true,
      fromDateTime: DriverDateFromUTC,
      toDateTime: DriverDateToUTC,
      deviceId: deviceId ? deviceId : false,
      clientNumber: 9939,
    };
  };

  const deviceIdFromViewport = (rowData) => {
    let newData = [];
    if (rowData)
      rowData.map((row) => {
        newData.push(row.deviceId);
      });
  };

  useEffect(() => {
    if (levelFilteredDrivers.viewportApi)
      deviceIdFromViewport(levelFilteredDrivers.viewportApi);
  }, [levelFilteredDrivers]);

  const updateTrips = (e) => {
    if (Object.keys(selectedDeviceId).length > 10) {
      <MessageBar variant="alert">
        Please select up to 10 trips to view on map
      </MessageBar>;
    } else {
      dispatch(
        fetchLogRecordTrips(
          generateRequestPayload(Object.keys(selectedDeviceId))
        )
      );
      dispatch(putSelectedDriver(Object.keys(selectedDeviceId)));
    }
    dispatch(setLogRecordTripsColors(selectedDeviceColors));
  };

  const viewport = () => {
    setSelectedDeviceId([])
    setCurrentPage(1);
    dispatch(isPanningMap(true));
    dispatch(hitViewportApi(true));
  };

  const clearTrips = (e) => {
    setSelectedDeviceId([]);
    dispatch(clearData());
    dispatch(putSelectedDriver([]));
    dispatch(driverLastLocationInfo([false]));
  };

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(1);
  };

  const handleDateChange = (date) => {
    let currentDateTimestamp = new Date();
    const offset = currentDateTimestamp.getTimezoneOffset();
    currentDateTimestamp = new Date(new Date().getTime() - offset * 60 * 1000);
    let selectedDateTimestamp = new Date(
      new Date(date).getTime() - offset * 60 * 1000
    );
    const selectedDate = selectedDateTimestamp.toISOString().split("T")[0];
    const currentDate = currentDateTimestamp.toISOString().split("T")[0];
    if (selectedDate > currentDate) {
      dispatch(
        addToast({
          title: "Future dates are not supported",
          type: "error",
        })
      );
      return;
    } else {
      setSelectedTripDate(selectedDate);
      sessionStorage.setItem("selectedDate", selectedDate);
      dispatch(insertInitialFromTime(selectedDate));
    }
  };

  const openCalendar = () => {
    setIsOpen(true);
  };

  const onDateDialogClose = () => {
    setIsOpen(false);
  };

  const filteredDrivers = levelFilteredDrivers?.viewportApi?.slice(
    rowsPerPage * (currentPage - 1),
    rowsPerPage * currentPage
  );

  return (
    <Spacing top="86">
      <div>
        <LPTripsDrawer
          title={getLabel("display_trips", "Fleet Trips", prismicData)}
          shouldOpenDrawer={true}
          shouldDrawerOpened={true}
          isDisabled={false}
          dynamicWidth={true}
          primaryBtnText={"Save"}
        >
          <Text
            position="fixed"
            key={"Date"}
            component="p"
            size="s"
            color="petrolBlue"
            gutter
          >
            {"Date"}
          </Text>
          <div className={styles.calendarIcon}>
            {selectedTripDate ? (
              moment(selectedTripDate).format("DD")
            ) : (
              <Spacing pv={0.5} />
            )}
          </div>
          <CalendarTodayIcon
            htmlColor={"#4a4a4a"}
            style={{ cursor: "pointer" }}
            onClick={() => openCalendar()}
          />
          <div className={styles.hiddenDiv}>
            <DatePicker
              isOpen={isOpen}
              selectedDate={selectedTripDate}
              handleDateChange={handleDateChange}
              onClose={onDateDialogClose}
            />
          </div>
          <Text
            position="fixed"
            key={
              "Select up to 10 vehicles currently showing on the map to show trips"
            }
            component="p"
            size="s"
            color="petrolBlue"
            gutter
          >
            {
              "Select up to 10 vehicles currently showing on the map to show trips"
            }
          </Text>
          <hr size="2"></hr>

          <Grid justifyContent="left">
            <GridItem>
              {levelFilteredDrivers.viewportApi != null &&
                levelFilteredDrivers.viewportApi.length > 0 && (
                  <Text
                    position="fixed"
                    component="p"
                    size="s"
                    color="petrolBlue"
                    gutter
                    fontWeight="bold"
                  >
                    {`${
                      selectedTripDate
                        ? levelFilteredDrivers.viewportApi.length
                        : "0"
                    } Vehicles Selected`}
                  </Text>
                )}
            </GridItem>

            <GridItem>
              <Link
                component="button"
                variant="body2"
                underline="always"
                fontWeight="bold"
                onClick={() => {
                  updateTrips();
                  setViewOrHide(false);
                }}
              >
                View all
              </Link>
            </GridItem>
            <Spacing ph={0.5} />
            <GridItem>
              <Link
                component="button"
                variant="body2"
                underline="always"
                fontWeight="bold"
                onClick={() => {
                  setViewOrHide(true);
                }}
              >
                Hide all
              </Link>
            </GridItem>
            <Spacing ph={2} />
            <GridItem>
              {Object.keys(selectedDeviceId).length != null &&
                Object.keys(selectedDeviceId).length > 0 && (
                  <Text
                    position="fixed"
                    component="p"
                    size="s"
                    color="petrolBlue"
                    gutter
                  >
                    {`${Object.keys(selectedDeviceId).length} of ${
                      levelFilteredDrivers.viewportApi.length
                    } Showing`}
                  </Text>
                )}
            </GridItem>
          </Grid>

          <div className={styles.test}>
            {levelFilteredDrivers.viewportLoadingStatus && (
              <LPLoader
                loading={levelFilteredDrivers.viewportLoadingStatus}
                message={"Loading Data ..."}
              />
            )}
            {selectedTripDate &&
            levelFilteredDrivers.viewportApi != null &&
            levelFilteredDrivers.viewportLoadingStatus !== true ? (
              filteredDrivers.map((row) => {
                // Define an array of colors
                const id = row.deviceId;
                const color = selectedDeviceColors[id];
                return (
                  <TripDeviceRow
                    key={row.deviceId}
                    id={row.deviceId}
                    rowData={row}
                    onSelected={onDeviceIdSelected}
                    selectedDeviceId={
                      viewOrHide ? viewOrHide : selectedDeviceId
                    }
                    color={color}
                  />
                );
              })
            ) : (
              <EmptyPage />
            )}
          </div>

          {Object.keys(selectedDeviceId).length != null &&
            Object.keys(selectedDeviceId).length > 10 && (
              <MessageBar variant="alert">
                Please select up to 10 trips to view on map
              </MessageBar>
            )}

          <TablePagination
            rowsPerPageOptions={[8, 12, 16, 20]}
            component="div"
            count={
              levelFilteredDrivers.viewportApi?.length
                ? levelFilteredDrivers.viewportApi.length
                : 0
            }
            rowsPerPage={rowsPerPage}
            page={currentPage - 1}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />

          <div style={{ marginTop: "2rem" }} className={styles.rightSide}>
            <Grid justifyContent="center">
              {Object.keys(selectedDeviceId).length ? (
                <Button
                  variant="secondary"
                  size="s"
                  onClick={(event) => {
                    clearTrips();
                  }}
                >
                  Back
                </Button>
              ) : null}
              <Spacing ph={2} pv={2} />
              <Button
                variant={selectedTripDate ? "primary" : "transparent"}
                label="find trip"
                size="s"
                onClick={(event) => {
                  viewport();
                }}
              >
                Refresh Map
              </Button>
              <Spacing ph={2} pv={2} />
              <Button
                variant={selectedTripDate ? "primary" : "transparent"}
                label="find trip"
                size="s"
                onClick={(event) => {
                  updateTrips();
                }}
              >
                Show Trips
              </Button>
            </Grid>
          </div>
        </LPTripsDrawer>
      </div>
    </Spacing>
  );
};

TripFilters.propTypes = {};

export default React.memo(TripFilters);
