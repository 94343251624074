import CONSTANTS from "../Constants/levels";

export const fetchDriversViaLevels = (params) => ({
    type: CONSTANTS.FETCH_DRIVERS_VIA_LEVEL,
    payload: params
});

export const putDriverLevels = (params) => ({
    type: CONSTANTS.PUT_DRIVER_LEVELS,
    payload: params
});

export const putSelectedDriver = (params) => ({
    type: CONSTANTS.PUT_SELECTED_DRIVER,
    payload: params
});

export const putViewportLocation = (params) => ({
    type: CONSTANTS.PUT_VIEWPORT_LOCATION,
    payload: params
});

export const clearData = (params) => ({
    type: CONSTANTS.CLEAR_DATA,
    payload: params
});

export const viewportLoadingStatusWorker = (params) => ({
    type: CONSTANTS.VIEWPORT_LOADING_STATUS,
    payload: params
});

export const logRecordLoadingStatusWorker = (params) => ({
    type: CONSTANTS.LOG_RECORD_LOADING_STATUS,
    payload: params
});

export const driverLastLocationInfo = (params) => ({
    type: CONSTANTS.DRIVER_LAST_LOCATION_INFO,
    payload: params
});

export const putViewportDriver = (params) => ({
    type: CONSTANTS.PUT_VIEWPORT_DRIVER,
    payload: params
});

export const hitViewportApi = (params) => ({
    type: CONSTANTS.HIT_VIEWPORT_API,
    payload: params
});

export const removeEventsFromDisplay = (params) => ({
    type: CONSTANTS.REMOVE_EVENTS_FROM_DISPLAY,
    payload: params
});

export const isPanningMap = (params) => ({
    type: CONSTANTS.IS_PANNING_MAP,
    payload: params
});

export const setDrivers = (data) => ({
    type: CONSTANTS.SET_DRIVERS,
    payload: data
})

export const resetDrivers = () => ({
    type: CONSTANTS.RESET_DRIVERS
})