import React from "react";
import styles from "../TripFilters.module.scss";
import searchIcon from "../../../Asset/MapIcons/search_icon.svg";
import { Spacing, Text } from "@leaseplan/ui";
const EmptyPage = () => {
  return (
    <div className={styles.emptyPageContainer}>
      <div className={styles.emptyPageBorder}>
        <img src={searchIcon} alt="Search" className={styles.searchImage} />
        <Spacing ph={1} pv={1} />
        <Text
          className={styles.messageText}
          position="fixed"
          component="p"
          size="s"
          color="petrolBlue"
          gutter
        >
          {"Select Date before continuing"}
        </Text>
        <Spacing ph={1} pv={1} />
        <Text
          className={styles.messageText}
          position="fixed"
          component="p"
          size="s"
          color="petrolBlue"
          gutter
        >
          {
            "Default area code will be used if the location search is left blank"
          }
        </Text>
      </div>
    </div>
  );
};
export default EmptyPage;
