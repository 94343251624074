import React, { useEffect } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";

import { Heading, Spacing } from "@leaseplan/ui";
import styles from "./LPTripsDrawer.module.scss";
import { useDispatch } from "react-redux";
import { clearData } from "../../Actions/level.action";

const useStyles = makeStyles((theme) => ({
  grid: {
    width: 320,
  },
  customGridWidth: {
    width: "32em",
    marginTop: "-1em",
    overflowX: "hidden",
    overflowY: "auto",
  },
  drawerHeader: {
    height: "7em",
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0.2, 3),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "space-between",
  },
  drawer: {
    color: "#DADADA",
    Opacity: "-1",
  },
  paper: {
    top: "70px",
    height: "92%",
  },
}));

export default function LPTripsDrawer(props) {
  const {
    title,
    shouldOpenDrawer,
    shouldDrawerOpened,
    drawerAlignment = "left",
    dynamicWidth = false,
  } = props;

  const classes = useStyles();
  const dispatch = useDispatch();
  const [drawerOpen, setDrawerOpen] = React.useState({ right: false });

  useEffect(() => {
    setDrawerOpen({ ...drawerOpen, right: !!shouldDrawerOpened });
    dispatch(clearData());
  }, [shouldDrawerOpened]);

  return (
    <Drawer
      BackdropProps={{ invisible: true }}
      classes={{ paper: classes.paper }}
      open={drawerOpen.right}
      anchor={drawerAlignment}
      className={classes.drawer}
      style={{ zIndex: "none", position: "none" }}
    >
      <div className={classes.drawerHeader}>
        <Heading size="xs">{title}</Heading>
      </div>
      <Spacing
        p={2}
        pt={0}
        className={
          props.width
            ? styles.customWidth
            : clsx({
                [classes.grid]: true,
                [classes.MuiBackdropRoot]: true,
                [classes.customGridWidth]: dynamicWidth,
              })
        }
      >
        {props.children}
      </Spacing>
    </Drawer>
  );
}
