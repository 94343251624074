import ComingSoon from "../Components/ComingSoon/ComingSoon";
import Dashboard from '../Components/Dashboard/Dashboard';
import Search from '../Components/Search/Search';
import DriverDetails from "../Components/DriverInsights/DriverDetails/DriverDetails";
import DriverInsights from "../Components/DriverInsights/DriverInsights";
import VehicleInsights from "../Components/VehicleInsights/VehicleInsights";
import Login from "../Components/Login/Login";
import PageNotFound from "../Components/PageNotFound/PageNotFound";
import Reporting from "../Components/Reporting/Reporting";
import VehicleDetails from "../Components/VehicleInsights/VehicleDetails/VehicleDetails";
import TripAdmin from "../Components/TripAdmin/TripAdmin";
import FleetView from "../Components/FleetTrip/TripFilters"

export const RoutePath = {
  Login: "/login",
  Dashboard: "/",
  VehicleInsights: "/vehicle",
  DriverInsights: "/driver",
  Reporting: "/reporting",
  OneScore: "/onescore",
  Rules: "/rules",
  TripAdministration: "/trip-administration",
  Search: "/search",
  DriverDetails: '/driver/:driverId/:date',
  VehicleDetails: '/vehicle/:vin/:driverId/:date',
  FleetView: "/fleet-view",
  PageNotFound: '*',
};

//Please Define path="/" at the end of the list
export const routes = [
  {
    name: "Login",
    path: RoutePath.Login,
    component: Login,
    hidden: true,
    isPrivate: false,
  },
  {
    name: "Home",
    path: RoutePath.Dashboard,
    component: Dashboard,
    isPrivate: true,
  },
  {
    name: "Driver Insights",
    path: RoutePath.DriverInsights,
    component: DriverInsights,
    isPrivate: true,
  },
  {
    name: "Vehicle Insights",
    path: RoutePath.VehicleInsights,
    component: VehicleInsights,
    isPrivate: true,
  },
  {
    name: "Rules",
    path: RoutePath.Rules,
    component: ComingSoon,
    isPrivate: true,
    hidden: true
  },
  {
    name: "Reporting",
    path: RoutePath.Reporting,
    component: Reporting,
    isPrivate: true,
  },
  {
    name: "One Score",
    path: RoutePath.OneScore,
    component: ComingSoon,
    isPrivate: true,
    hidden: true
  },
  {
    name: "Trip Administration",
    path: RoutePath.TripAdministration,
    component: TripAdmin,
    isPrivate: true,
    hidden: true
  },
  {
    name: "Search",
    path: RoutePath.Search,
    component: Search,
    hidden: true,
    isPrivate: true,
  },
  {
    name: 'Driver Details',
    path: RoutePath.DriverDetails,
    component: DriverDetails,
    hidden: true,
    isPrivate: true,
  },
  {
    name: 'Vehicle Details',
    path: RoutePath.VehicleDetails,
    component: VehicleDetails,
    hidden: true,
    isPrivate: true
  },
  {
    name: 'Page Not Found',
    path: RoutePath.PageNotFound,
    component: PageNotFound,
    hidden: true,
    isPrivate: false,
  },
  {
    name: 'Fleet Trips',
    path: RoutePath.FleetView,
    component: FleetView,
    isPrivate: true
  },
];
