import axios from "../AxiosInterceptor";
import { call, put, takeEvery, select, takeLatest } from "redux-saga/effects";
import CONSTANT from "../Constants/driver";
import { baseURL } from "../Store/base-url";
import { F, S } from "../utils/actions";
import { CANCEL } from 'redux-saga';

function getDriverDetailsApi(params) {
  return axios.request({
    baseURL: baseURL,
    responseType: "json",
    url: `driver-insights/retrieve-driver-insights`,
    method: "post",
    data: {
      ...params
    }
  });
}

function getDriverCoursesApi(params) {
  return axios.request({
    baseURL: baseURL,
    responseType: "json",
    url: `safety-training`,
    method: "post",
    data: {
      ...params
    }
  });
}

function getDriverCommunicationsApi(params) {
  return axios.request({
    baseURL: baseURL,
    responseType: "json",
    url: `safety-training`,
    method: "post",
    data: {
      ...params
    }
  });
}

function getDriverTripsApi(params) {
  return axios.request({
    baseURL: baseURL,
    responseType: "json",
    url: `get-trips-driver-vin`,
    method: "post",
    data: {
      ...params
    }
  });
}

function getDriverLogRecordTripsApi(params) {
  return axios.request({
    baseURL: baseURL,
    responseType: "json",
    url: `log-record-zoned`,
    method: "post",
    data: {
      ...params
    }
  });
}

function getViewportDeviceApi(params) {
  return axios.request({
    baseURL: baseURL,
    responseType: "json",
    url: `log-device-viewport`,
    method: "post",
    data: {
      ...params
    }
  });
}

function getDeviceLastKnownLocationApi(params) {
  return axios.request({
    baseURL: baseURL,
    responseType: "json",
    url: `dvl/get-device-last-known-location/${params[0]}/${params[1]}`,
    method: "get",
  });
}


function getDriverEventsApi(params) {
  const tokenSource = axios.CancelToken.source();
  const request = axios.request({
    baseURL: baseURL,
    responseType: "json",
    url: `events/selected-driver-events`,
    method: "post",
    data: {
      ...params
    },
    cancelToken: tokenSource.token,
  });
  request[CANCEL] = () => tokenSource.cancel();
  return request;
}

function getServiceEventsApi(params) {
  const tokenSource = axios.CancelToken.source();
  const request = axios.request({
    baseURL: baseURL,
    responseType: "json",
    url: `service-events/getServiceEvents`,
    method: "post",
    data: {
      ...params
    },
    cancelToken: tokenSource.token,
  });
  request[CANCEL] = () => tokenSource.cancel();
  return request;
}

function getDriverOneScoreApi(params, clientNumber) {
  const driverNumber = params.driverRecordNumber;
  return axios.request({
    baseURL: baseURL,
    responseType: "json",
    url: `one-score-overall-info/${clientNumber}/${driverNumber}`,
    method: "get",
  });
}

export function* getDriverData(action) {
  try {
    const { data } = yield call(getDriverDetailsApi, action.payload);
    yield put({ type: S(action.type), payload: data });
  } catch (e) {
    yield put({ type: F(action.type), error: e });
  }
}

export function* getDriverCourses(action) {
  try {
    const { data } = yield call(getDriverCoursesApi, action.payload);
    yield put({ type: S(action.type), payload: data });
  } catch (e) {
    yield put({ type: F(action.type), error: e });
  }
}

export function* getDriverTrips(action) {
  try {
    const { data } = yield call(getDriverTripsApi, action.payload);
    yield put({ type: S(action.type), payload: data.content ? data.content : data });
  } catch (e) {
    yield put({ type: F(action.type), error: e });
  }
}

export function* getDriverLogRecordTrips(action) {
  if (action.payload === false) {
    const data = []
    yield put({ type: S(action.type), payload: data });
  }
  else {
    try {
      const { data } = yield call(getDriverLogRecordTripsApi, action.payload);
      yield put({ type: S(action.type), payload: data.content ? data.content : data });
    } catch (e) {
      yield put({ type: F(action.type), error: e });
    }
  }
}

export function* getViewportDeviceApiWorker(action) {
  if (action.payload === false) {
    const data = []
    yield put({ type: S(action.type), payload: data });
  }
  else {
    try {
      const { data } = yield call(getViewportDeviceApi, action.payload);
      yield put({ type: S(action.type), payload: data.content ? data.content : data });
    } catch (e) {
      yield put({ type: F(action.type), error: e });
    }
  }
}

export function* getDeviceLastKnownLocationApiWorker(action) {
  if (action.payload[0] === false) {
    const data = null
    yield put({ type: S(action.type), payload: data });
  } else {
    try {
      const { data } = yield call(getDeviceLastKnownLocationApi, action.payload);
      yield put({ type: S(action.type), payload: data.content ? data.content : data });
    } catch (e) {
      yield put({ type: F(action.type), error: e });
    }
  }
}

export function* getDriverCommunication(action) {
  try {
    const { data } = yield call(getDriverCommunicationsApi, action.payload);
    yield put({ type: S(action.type), payload: data ? data : [] });
  } catch (e) {
    yield put({ type: F(action.type), error: e });
  }
}

export function* getDriverEvents(action) {
  try {
    const { data } = yield call(getDriverEventsApi, action.payload);
    yield put({ type: S(action.type), payload: data ? data : [] });
  } catch (e) {
    yield put({ type: F(action.type), error: e });
  }
}

export function* getServiceEvents(action) {
  try {
    const { data } = yield call(getServiceEventsApi, action.payload);
    yield put({ type: S(action.type), payload: data ? data : [] });
  } catch (e) {
    yield put({ type: F(action.type), error: e });
  }
}

export function* getDriverOneScoreWorker(action) {
  try {
    //TODO:: Can be removed once the clientNumber is removed from the backend (As authentication supplies client number directly)
    const clientNumber = yield select(state => state.auth.user.clientNumber)
    const { data } = yield call(getDriverOneScoreApi, action.payload, clientNumber);
    yield put({ type: S(action.type), payload: data ? data : [] });
  } catch (e) {
    yield put({ type: F(action.type), error: e });
  }
}

function* getInitalDriverDataWorker(action) {
  yield put({ type: S(action.type), payload: action.payload })
}

function* getInitalToTimeWorker(action) {
  yield put({ type: S(action.type), payload: action.payload })
}

function* getInitalFromTimeWorker(action) {
  yield put({ type: S(action.type), payload: action.payload })
}

function* getSelectedEventWorker(action) {
  yield put({ type: S(action.type), payload: action.payload })
}

function* getSingleDriverLogRecordTripsApi(action) {
  yield put({ type: S(action.type), payload: action.payload })
}

function* setLogRecordTripsColorsWorker(action) {
  yield put({ type: S(action.type), payload: action.payload })
}

function* driverSaga() {
  yield takeEvery(CONSTANT.INSERT_INITIAL_DRIVER_DETAILS, getInitalDriverDataWorker);
  yield takeEvery(CONSTANT.INSERT_TO_TIME, getInitalToTimeWorker);
  yield takeEvery(CONSTANT.INSERT_FROM_TIME, getInitalFromTimeWorker);
  yield takeEvery(CONSTANT.INSERT_TO_EVENT, getSelectedEventWorker);
  yield takeEvery(CONSTANT.FETCH_DRIVER_DETAILS, getDriverData);
  yield takeEvery(CONSTANT.FETCH_COURSES, getDriverCourses);
  yield takeEvery(CONSTANT.FETCH_TRIPS, getDriverTrips);
  yield takeEvery(CONSTANT.FETCH_LOG_RECORD_TRIPS, getDriverLogRecordTrips);
  yield takeEvery(CONSTANT.FETCH_SINGLE_DEVICE_LAST_LOCATION, getDeviceLastKnownLocationApiWorker);
  yield takeEvery(CONSTANT.FETCH_VIEWPORT_LOG_DEVICE, getViewportDeviceApiWorker)
  yield takeEvery(CONSTANT.FETCH_DEVICE_LAST_LOCATION, getDeviceLastKnownLocationApiWorker);
  yield takeEvery(CONSTANT.FETCH_SINGLE_LOG_RECORD_TRIPS, getSingleDriverLogRecordTripsApi);
  yield takeEvery(CONSTANT.FETCH_COMMUNICATION, getDriverCommunication);
  yield takeLatest(CONSTANT.FETCH_EVENTS, getDriverEvents);
  yield takeLatest(CONSTANT.FETCH_SERVICE_EVENTS, getServiceEvents);
  yield takeEvery(CONSTANT.FETCH_ONE_SCORE, getDriverOneScoreWorker);
  yield takeEvery(CONSTANT.SET_LOG_RECORD_TRIPS_COLORS, setLogRecordTripsColorsWorker);
}


export default driverSaga;