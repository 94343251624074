import { useState, useEffect, useRef } from 'react';
import { baseURL } from "../../Store/base-url";
import { simplifyPath } from "../../Components/MapComponents/SimplifyPolyline";
import { useMap } from 'react-leaflet';
import { useDispatch, useSelector } from 'react-redux';
import { hitViewportApi, isPanningMap, putViewportDriver, putViewportLocation, viewportLoadingStatusWorker } from '../../Actions/level.action';
import { fetchLogDeviceViewportDataUsingCancelToken } from '../../AxiosInterceptor/fetchLogDeviceViewportDataUsingCancelToken';
import moment from 'moment';

const useFetchVehicleDataViewPort = (method, url, body, threshold, fromTime, toTime, delay = 1000) => {
  const [isLoadingVp, setIsLoading] = useState(false);
  const timelineSelector = useSelector(state => state.timeline)
  const [resp, setResp] = useState([]);
  const [serverError, setServerError] = useState(null);
  const map = useMap();
  const dispatch = useDispatch();
  const levelDescription = useSelector(state => state.levels.driverLevels);
  const levelFilteredDrivers = useSelector(state => state.levels);


  // today's date using timezone is effecting calendar date so using the system new Date func.
    const todaysDate = moment().tz(sessionStorage.getItem("Timezone") ? sessionStorage.getItem("Timezone") : "America/New_York").format('YYYY-MM-DD hh:mm:ss');

  const fetchData1 = async () => {
    let bbox = map.getBounds();

    let { lat: f_x, lng: f_y } = bbox.getSouthEast();
    let { lat: s_x, lng: s_y } = bbox.getNorthWest();

    let query = {
      bottomRightLat: f_x,
      bottomRightLng: f_y,
      topLeftLat: s_x,
      topLeftLng: s_y,
    }
    setIsLoading(true);
    dispatch(putViewportLocation(query));
    return query;
  }

  useEffect(() => {
    if (timelineSelector.clockData.event === "mouseup" && window.location.pathname.split("/").length === 1) {
      fetchData();
    }

  }, [timelineSelector.clockData.fromTime, timelineSelector.clockData.toTime])

  useEffect(() => {
    if (levelFilteredDrivers.hitViewportApi) {
      fetchData();
      dispatch(hitViewportApi(false))
    }

  }, [levelFilteredDrivers.hitViewportApi])


  //panning and dragging calling viewportApi
  useEffect(() => {
    map.on('zoom', () => {
      dispatch(isPanningMap(true))
    })
    return () => {
      map.off('zoom')
    }

  }, [url, method, body.clientNumber, fromTime, toTime]);

  useEffect(() => {
    map.on('dragend', () => {
      dispatch(isPanningMap(true))
    })
    return () => {
      map.off('dragend')
    }
  }, [url, method, body.clientNumber, fromTime, toTime]);

  const fetchData = async () => {
    const try1 = fetchData1();
    try {
      const { cancelPrevQuery, result, error } = await fetchLogDeviceViewportDataUsingCancelToken(
        baseURL,
        "json",
        url,
        method,
        {
          ...body,
          fromDateTime: `${fromTime[0]?fromTime:todaysDate}`,
          toDateTime: `${toTime[0]?toTime:todaysDate}`,
          topLeftLat: (await try1).topLeftLat,
          topLeftLng: (await try1).topLeftLng,
          bottomRightLat: (await try1).bottomRightLat,
          bottomRightLng: (await try1).bottomRightLng,
          level1: levelDescription && levelDescription[0] ? levelDescription[0].map(function (el) { return el.trim(); }) : [],
          level2: levelDescription && levelDescription[1] ? levelDescription[1].map(function (el) { return el.trim(); }) : [],
          level3: levelDescription && levelDescription[2] ? levelDescription[2].map(function (el) { return el.trim(); }) : [],
          level4: levelDescription && levelDescription[3] ? levelDescription[3].map(function (el) { return el.trim(); }) : [],
          level5: levelDescription && levelDescription[4] ? levelDescription[4].map(function (el) { return el.trim(); }) : [],
          level6: levelDescription && levelDescription[5] ? levelDescription[5].map(function (el) { return el.trim(); }) : [],
          level7: levelDescription && levelDescription[6] ? levelDescription[6].map(function (el) { return el.trim(); }) : [],
          level8: levelDescription && levelDescription[7] ? levelDescription[7].map(function (el) { return el.trim(); }) : [],
          level9: levelDescription && levelDescription[8] ? levelDescription[8].map(function (el) { return el.trim(); }) : [],
          level10: levelDescription && levelDescription[9] ? levelDescription[9].map(function (el) { return el.trim(); }) : [],
        }
      );

      if (cancelPrevQuery) return;

      const data = result
      let newData = [];
      dispatch(viewportLoadingStatusWorker(isLoadingVp));
      for (let i = 0; i < data.length; i++) {
        const deviceId = data[i].deviceId;
        const deviceName = data[i].data.deviceName;
        const clientVehicleId = data[i].data.clientVehicleId;
        const logRecordData = simplifyPath(data[i].data.locations, threshold);
        newData.push({ deviceId, logRecordData, deviceName, clientVehicleId });

      }
      dispatch(putViewportDriver(newData));
      setResp(newData);
    }
    catch (error) {
      setServerError(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    dispatch(viewportLoadingStatusWorker(isLoadingVp));
  }, [!isLoadingVp]);

  return { isLoadingVp, resp };
};

export default useFetchVehicleDataViewPort;

