import { S, F } from "../utils/actions";
import CONSTANTS from '../Constants/levels'


//Different Filters present for the map
const INITIAL_STATE = {
    drivers: [],
    data: [],
    isLoading: false,
    error: null,
    viewportLoadingStatus:false
};

const levels = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CONSTANTS.FETCH_DRIVERS_VIA_LEVEL:
            return { 
                ...state,
                isLoading: true
            };
        case S(CONSTANTS.FETCH_DRIVERS_VIA_LEVEL):
            return { 
                ...state,
                isLoading: false,
                data: action.payload,
                error: null
            };
        case F(CONSTANTS.FETCH_DRIVERS_VIA_LEVEL):
            return { 
                ...state,
                isLoading: false,
                data: [],
                error: action.error
            };
        case CONSTANTS.PUT_SELECTED_DRIVER:
            return {
                ...state,
                isLoading: true
            };
        case S(CONSTANTS.PUT_SELECTED_DRIVER):
            return {
                ...state,
                isLoading: false,
                selectedDrivers: action.payload,
                error: null
            };
        case F(CONSTANTS.PUT_SELECTED_DRIVER):
            return {
                ...state,
                isLoading: false,
                selectedDrivers: [],
                error: action.error
            };

        case CONSTANTS.PUT_DRIVER_LEVELS:
        return {
            ...state,
            isLoading: false,
            driverLevels: action.payload
        };

        case CONSTANTS.PUT_VIEWPORT_LOCATION:
            return {
                ...state,
                isLoading: false,
                viewportLocation: action.payload
            };

            case CONSTANTS.CLEAR_DATA:
                console.log('coming')
            return {
                ...state,
                isLoading: false,
                viewportApi: null
            };

            case CONSTANTS.VIEWPORT_LOADING_STATUS:
                return {
                    ...state,
                    isLoading: false,
                    viewportLoadingStatus: action.payload
                };

        case CONSTANTS.LOG_RECORD_LOADING_STATUS:
        return {
            ...state,
            isLoading: false,
            logRecordLoadingStatus: action.payload
        };
        
        case CONSTANTS.DRIVER_LAST_LOCATION_INFO:
            return {
                ...state,
                isLoading: false,
                driverLastLocationInfo: action.payload
            };

        case CONSTANTS.PUT_VIEWPORT_DRIVER:
            return {
                ...state,
                viewportApi: action.payload
            };

        case CONSTANTS.HIT_VIEWPORT_API:
        return {
            ...state,
            hitViewportApi: action.payload
        };

        case CONSTANTS.REMOVE_EVENTS_FROM_DISPLAY:
        return {
            ...state,
            removeEventsFromDisplay: action.payload
        };

        case CONSTANTS.IS_PANNING_MAP:
        return {
            ...state,
            isPanningMap: action.payload
        };

        case CONSTANTS.SET_DRIVERS:
            return { 
                ...state,
                drivers: action.payload
            };
        case CONSTANTS.RESET_DRIVERS:
            return { 
                ...state,
                drivers: []
            };
        default:
          return state;
      }
}

export default levels;